import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/Layout2'
import SEO from '../components/seo'

import SplitText from 'react-pose-text'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './engagement.css'

class EngagementPage extends React.Component {
  render() {
    const siteTitle =
      'WebriQ is pioneering a collaborative approach to web design and development'
    const siteDescription = this.props.data.site.siteMetadata.description

    const charPoses = {
      exit: { opacity: 0, y: 20 },
      enter: {
        opacity: 1,
        y: 0,
        delay: ({ charIndex }) => charIndex * 30,
      },
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="hero">
          <div id="hero-wave" className="engagement">
            <div className="hero-engagement-1 hero-wave" />
            <div className="hero-engagement-2 hero-wave" />
          </div>
          <div className="hero-sub">
            <Container>
              <Row>
                <Col className="py-5 text-center">
                  <h1 className="mt-4 text-white">
                    <SplitText
                      initialPose="exit"
                      pose="enter"
                      charPoses={charPoses}
                    >
                      Supercharge your website enagagement
                    </SplitText>
                  </h1>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <section id="track" className="component">
          <Container>
            <div className="text-center">
              <LazyLoadImage
                className="img-fluid"
                effect="blur"
                src="/img/sales-iq.png"
              />
            </div>
            <Row className="align-items-center row justify-content-center">
              <Col md={6} className="ml-lg-auto">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid mt-2"
                        effect="blur"
                        src="/img/offer-1.png"
                        alt="3"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Track and engage visitors.
                      </h5>
                      <p>
                        Understand how visitors are interacting with your
                        website in real-time. Use that insight to trigger
                        rule-based proactive chat conversations.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/salesiq-track.png"
                    alt="performance"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="livechat" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col
                md={5}
                className="text-center mb-4 mb-lg-0 order-2 order-md-1"
              >
                <LazyLoadImage
                  className="img-fluid"
                  effect="blur"
                  src="/img/salesiq-chatbot.png"
                  alt="chatbot"
                />
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid mt-2"
                        effect="blur"
                        src="/img/offer-2.png"
                        alt="2"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Live Chat Software.
                      </h5>
                      <p>
                        Letting customers chat with a live representative helps
                        you close deals faster and deliver exceptional customer
                        service.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="be-online" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid mt-2"
                        effect="blur"
                        src="/img/offer-3.png"
                        alt="3"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Be online. Always.
                      </h5>
                      <p>
                        Chat with prospects from the comfort of your couch or in
                        the middle of a movie. Zoho SalesIQ supports both mobile
                        and tablet devices, so you can connect with your
                        prospects and customers on the go.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/salesiq-chat.svg"
                    alt="chat"
                    width="284"
                    height="264"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="engage" className="bg-blue text-center text-white">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <h4 className="my-0">Engage your visitors 24/7 with Bots</h4>
                <p className="my-4">
                  Automate the first-level engagement and gather the basic
                  information your leads such as name, email address, etc.
                  Chatbots can also perfectly index FAQ articles (inside the
                  in-chat knowledge base) or other help guides, albeit in an
                  interactive way.
                </p>
                <a
                  className="hvr-shadow mb-0 btn btn-primary btn-orange"
                  href="https://payments.zoho.com/ResellerCustomerSignUp.do?id=f96e1397da8f7ecbb3c0ba0962263da5"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Access Zoho SalesIQ
                </a>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="zoho-crm-plus" className="bg-light-purple text-center">
          <Container>
            <Row>
              <Col className="text-center">
                <LazyLoadImage
                  effect="blur"
                  src="/img/zoho.png"
                  className="img-fluid mb-0"
                  alt="Zoho"
                />
                <h1 className="mt-4">
                  <SplitText
                    initialPose="exit"
                    pose="enter"
                    charPoses={charPoses}
                  >
                    Zoho CRM Plus
                  </SplitText>
                </h1>
                <h5 className="mt-0">End-to-end Customer Engagement</h5>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="omnichannel" className="component">
          <Container>
            <Row className="align-items-center row justify-content-center">
              <Col md={6} className="ml-lg-auto">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid mt-2"
                        effect="blur"
                        src="/img/offer-1.png"
                        alt="3"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        Omnichannel Engagement
                      </h5>
                      <p>
                        Today's customers are ready to be engaged across various
                        channels. Bridge the gap between your sales, marketing,
                        and support teams with Zoho CRM Plus, the unified
                        Customer Experience Platform. Provide the best customer
                        experience across every channel: phone, email, live
                        chat, surveys, and social media.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/crm-omni-channel.png"
                    alt="omni-channel"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="more-than-crm" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col
                md={5}
                className="text-center mb-4 mb-lg-0 order-2 order-md-1"
              >
                <LazyLoadImage
                  className="img-fluid"
                  effect="blur"
                  src="/img/crm-more-than-crm.svg"
                  alt="crm-more-than-crm"
                  width="284"
                  height="264"
                />
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid mt-2"
                        effect="blur"
                        src="/img/offer-2.png"
                        alt="2"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">More than CRM</h5>
                      <p>
                        A powerful and unified customer experience platform.
                        Combined with powerful analytics, this unique platform
                        allows you to keep track of every customer interaction
                        within a single interface, helping you increase
                        productivity across the business and improve customers'
                        satisfaction.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="mobility" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid mt-2"
                        effect="blur"
                        src="/img/offer-3.png"
                        alt="3"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Mobility</h5>
                      <p>
                        A full range of mobile apps to help your teams stay
                        informed, ready to engage customers, and make decisions
                        on the go.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/crm-mobility.svg"
                    alt="content"
                    width="263"
                    height="313"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="one-suite" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={5} className="text-center order-2 order-md-1">
                <LazyLoadImage
                  className="img-fluid"
                  effect="blur"
                  src="/img/crm-one-suite.png"
                  alt="one-suite"
                />
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage
                        className="img-fluid mt-2"
                        effect="blur"
                        src="/img/offer-4.png"
                        alt="4"
                      />
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">
                        One suite for all customer-facing applications
                      </h5>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="access-crm">
          <Container>
            <div className="text-center">
              <a
                className="hvr-shadow mb-0 btn btn-primary btn-orange"
                href="https://payments.zoho.com/ResellerCustomerSignUp.do?id=63cdd4b46482b3aaf0870c0480fddd7f"
                target="_blank"
                rel="noreferrer noopener"
              >
                Access Zoho CRM Plus
              </a>
            </div>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default EngagementPage

export const engagementPageQuery = graphql`
  query engagementPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
