import React from 'react'
import NoScript from 'react-noscript'
import Header from './Header'
import Footer from './Footer'
import 'bootstrap/dist/css/bootstrap.css'
import 'cookieconsent/build/cookieconsent.min.css'
import './layout.css'
import './form.css'

class Layout extends React.Component {
  componentDidMount() {
    if (window) {
      function handleKeyUp(e) {
        if (e.target.value.length === 0) {
          this.setAttribute('class', 'form-group')
        } else {
          this.setAttribute('class', 'form-group active')
        }
      }
      var formField = document.getElementsByClassName('form-group')
      for (var i = 0; i < formField.length; i++) {
        formField[i].addEventListener('keyup', handleKeyUp)
      }
      for (var e = 0; e < formField.length; e++) {
        formField[e].addEventListener('change', handleKeyUp)
      }
      window.$zoho = window.$zoho || {}
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode:
          '0a9426385da7558ba09f511f3e4bcc25d38ddb6f494d3c5f4b76203a52b84011',
        values: {},
        ready: function() {},
      }
      var d = document
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.id = 'zsiqscript'
      s.defer = true
      s.src = 'https://salesiq.zoho.com/widget'
      var t = d.getElementsByTagName('script')[0]
      t.parentNode.insertBefore(s, t)
      var z = d.createElement('div')
      z.setAttribute('id', 'zsiqwidget')
      d.body.appendChild(z)

      const script = document.createElement('script')
      script.src = `//cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js`
      const headScript = document.getElementsByTagName('script')[0]
      headScript.parentNode.insertBefore(script, headScript)

      window.addEventListener('load', function() {
        window.cookieconsent.initialise({
          container: document.getElementById('cookieChoiceInfo'),
          palette: {
            popup: {
              background: '#f5f5f5',
              text: '#031121',
            },
            button: {
              background: '#007bff',
            },
          },
          position: 'bottom-left',
          theme: 'classic',
          content: {
            message:
              'Cookies help us deliver our services. By using our services, you agree to our use of cookies.',
            href: '/cookies',
            dismiss: 'Got it!',
            target: '_self',
          },
        })
      })
    }
  }
  render() {
    const { location, children, currentpage } = this.props
    return (
      <div id="outer-container">
        <Header location={location} currentpage={currentpage} />
        <main>{children}</main>
        <Footer location={location} />
        <div id="cookieChoiceInfo" className="text-center" />
        <NoScript>
          <div style={{ display: 'inline' }}>
            <img
              height="1"
              width="1"
              style={{ borderStyle: `none` }}
              alt=""
              src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1018229817/?guid=ON&script=0"
            />
          </div>
        </NoScript>
      </div>
    )
  }
}

export default Layout
